.footer-options {
  @apply flex justify-between flex-wrap;

  .footer-option {
    @apply flex flex-col items-start mt-10 lg:mt-16 w-7/12 sm:w-5/12 lg:w-2/12;

    img {
      @apply h-9 mt-8;
    }

    h2 {
      @apply text-lg text-dark font-medium mt-4;
    }

    p {
      @apply text-xs text-lightgray mt-4;
    }
  }
}
