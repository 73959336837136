@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cta {
  @apply text-xs xl:text-sm rounded-full px-6 py-3 font-semibold whitespace-nowrap border flex items-center;
}
.cta img {
  @apply ml-3;
}

.cta-hover {
  @apply transition duration-500 ease-in-out hover:-translate-y-0.5 transform;
}

.cta2 {
  @apply text-xs xl:text-sm rounded-full px-6 py-3 font-semibold whitespace-nowrap border flex items-center;
}
.cta2 img {
  @apply mr-3;
}

.inapp-input {
  @apply mt-2 w-full border-b border-orange py-4 text-sm bg-transparent text-lightgray focus:outline-none;
}

.srs-option {
  @apply mt-5 rounded-full text-center px-5 py-1 bg-f2 text-sm text-black focus:bg-orange mr-5 lg:max-w-1/3;
}

.input-wrapper {
  @apply mt-4 relative float-left box-border m-2 rounded-full mr-5;
}

.input-wrapper2 {
  @apply mt-5 relative float-left box-border rounded-full mr-4;
}

.input-wrapper .text,
.input-wrapper2 .text {
  @apply px-3 py-1 rounded-full w-full h-full flex justify-center items-center border border-blue text-blue text-sm;
  line-height: 25px;
  transition: 0.5s ease;
}

.input-wrapper input,
.input-wrapper2 input {
  @apply absolute px-3 py-4 w-full h-full;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.input-wrapper input[type="radio"]:checked ~ .text,
.input-wrapper2 input[type="radio"]:checked ~ .text {
  @apply border-orange text-white bg-orange;
}
.hovericon path {
  @apply fill-current stroke-current text-blue group-hover:text-orange;
}

.hoverarrow path {
  @apply fill-current group-hover:text-white;
}

.discount-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.6);
}

.discount-modal {
  @apply absolute rounded-lg top-1/2 left-1/2 right-auto bottom-auto p-0 border-0 outline-none focus:outline-none;
  margin-right: -50%;
  animation: slidedown 0.5s 1;
  animation-fill-mode: forwards;
}

@keyframes slidedown {
  0% {
    transform: translate(-50%, -100%);
    animation-timing-function: ease-out;
  }
  20% {
    transform: translate(-50%, -90%);
    animation-timing-function: ease-in;
  }
  40% {
    transform: translate(-50%, -80%);
    animation-timing-function: ease-out;
  }
  60% {
    transform: translate(-50%, -70%);
    animation-timing-function: ease-out;
  }
  80% {
    transform: translate(-50%, -60%);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translate(-50%, -50%);
    animation-timing-function: ease-in;
  }
}
