.rect {
  width: 60px;
  height: 80px;
}

.rect > div {
  background-color: #013271;
  height: 100%;
  width: 8px;
  display: inline-block;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.rect .rect2 {
  animation-delay: -1.1s;
}

.rect .rect3 {
  animation-delay: -1s;
}

.rect .rect4 {
  animation-delay: -0.9s;
}

.rect .rect5 {
  animation-delay: -0.8s;
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}
