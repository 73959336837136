.links {
  @apply flex flex-col w-full items-start mt-6 space-y-1 xl:mt-4;

  a {
    transition: 0.35s;
    @apply text-blue2 text-sm hover:opacity-50;
  }
}


