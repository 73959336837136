.clients {
  img {
    @apply w-2/12;
  }
}

h3 {
  @apply text-dark;
}

.features {
  @apply flex flex-wrap items-start;

  .feature {
    @apply flex flex-col items-center sm:items-start mt-10 lg:mt-16 mx-auto w-9/12 sm:w-6/12 lg:w-4/12 px-6 xl:px-12  text-center sm:text-left;

    img {
      @apply h-9 mt-8;
    }

    h2 {
      @apply text-xl text-white font-semibold mt-4;
    }

    p {
      @apply text-sm text-blue2 mt-4;
    }
  }
}

.testimonials {
  @apply sm:w-6/12 mt-10 sm:mt-0;
  button {
    @apply rounded-full p-6 flex justify-center border border-gray mt-5 transform-none;
  }
}

.content {
  -webkit-transition: height, 0.5s linear;
  -moz-transition: height, 0.5s linear;
  -ms-transition: height, 0.5s linear;
  -o-transition: height, 0.5s linear;
  transition: height, 0.5s linear;
}
.open {
  -webkit-transition: height, 0.5s linear;
  -moz-transition: height, 0.5s linear;
  -ms-transition: height, 0.5s linear;
  -o-transition: height, 0.5s linear;
  transition: height, 0.5s linear;
}
