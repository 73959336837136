$primary-color: #00005c;
$text-color: mix(#000, $primary-color, 64%);

.tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    background-color: #E7ECEF;
    color: #013271;
    border-radius: 6px;
    padding: 5px;
  
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    width: 250px;
    bottom: 100%;
    // left: 50%;
    // margin-left: -50px;
  }

  .tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color:  #E7ECEF transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }